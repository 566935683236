import React, { useState, useEffect } from 'react'
import * as st from '~/assets/styl/FilterGuide.module.styl'
import FilterForm from './FilterForm'
import api from '~/api'
import ResultFindGuide from './ResultFindGuide'
import cn from 'classnames'

interface RecordsProps {
  items: {
    name: string
    phone: string
    whatsapp: string
    state: string
    city: string
    neighborhood: string
  }[]
}

type StateProps = {
  id: string
  name: string
}

const FilterGuide = () => {
  const [state, setState] = useState('')
  const [city, setCity] = useState('')
  const [items, setItems] = useState<RecordsProps[]>([])
  const [statesApi, setStatesApi] = useState<StateProps[]>([])
  const [isLoading, setIsloading] = useState(false)

  useEffect(() => {
    api('get', 'shopping-guide/states')
      .then((data) => {
        const { states } = data
        setStatesApi(states)
        setIsloading(true)
      })
      .catch((err) => {
        console.error(err)
        setIsloading(true)
      })
  }, [])

  return (
    <div className={st.filterContainer}>
      <div className={st.filterWrapper}>
        <section className={st.filterInfo}>
          <h1 className={st.filterTitle}>Busque aqui</h1>
          <p className={st.filterDescription}>
            Para localizar um Guia de Compras da Bauer Turismo, realize uma
            busca por estado e cidade.
          </p>
        </section>

        {isLoading ? (
          statesApi.length > 0 ? (
            <FilterForm
              state={state}
              setState={setState}
              city={city}
              setCity={setCity}
              setItems={setItems}
              statesApi={statesApi}
            />
          ) : (
            <p className={cn(st.filterDescription, st.filterNotice)}>
              Não há guias registrados neste momento.
            </p>
          )
        ) : (
          <p className={cn(st.filterDescription, st.filterNotice)}>
            CARREGANDO...
          </p>
        )}
      </div>

      {items.length > 0 && (
        <>
          <ResultFindGuide
            state={state}
            city={city}
            items={items}
            statesApi={statesApi}
          />
        </>
      )}
    </div>
  )
}

export default FilterGuide
