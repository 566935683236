import React from 'react'
import * as st from '~/assets/styl/ResultFindGuide.module.styl'
import cn from 'classnames'

import iconWhats from '~/assets/img/svg/icon-whats.svg'
import iconEmail from '~/assets/img/svg/icon-email.svg'

interface ResultProps {
  state: string
  city: string
  items: RecordsProps[]
  statesApi: {
    id: string
    name: string
  }[]
}

interface RecordsProps {
  name: string
  phone: string
  whatsapp: string
  state: string
  city: string
  neighborhood: string
  email: string
}

const ResultFindGuide = ({ city, state, items, statesApi }: ResultProps) => {
  const [{ state: estado }] = items

  const [{ name: nameState }] = statesApi.filter((state) => {
    return state.id === estado
  })

  if (items.length === 0 && (state !== '' || city !== '')) {
    return (
      <div className={st.core} id="resultado">
        <div className={cn(st.heading, st.flexColumn)}>
          <p>Não possuímos guias nesta cidade.</p>
        </div>
      </div>
    )
  }

  return (
    <div className={st.core}>
      <div className={st.heading}>
        <h2>
          Resultado da busca:{' '}
          <span>
            {`"${nameState}${
              city
                ? city === 'todas'
                  ? ', Todas as cidades'
                  : ', ' + city
                : ''
            }"
						`}
          </span>
        </h2>
      </div>

      <div className={st.results}>
        <div className={st.result}>
          <div className={st.items}>
            <table>
              <thead>
                <tr>
                  <th>
                    <div>Nome do Guia</div>
                  </th>
                  <th>
                    <div>Estado</div>
                  </th>
                  <th>
                    <div>Cidade</div>
                  </th>
                  <th>
                    <div>Bairro</div>
                  </th>
                  <th>
                    <div>Telefone</div>
                  </th>
                  <th>
                    <div>E-mail</div>
                  </th>
                </tr>
              </thead>

              <tbody>
                {items.map(
                  (
                    { city, name, neighborhood, phone, whatsapp, email },
                    index
                  ) => (
                    <tr key={index}>
                      <td>
                        <b>{name}</b>
                      </td>
                      <td>{nameState}</td>
                      <td>{city}</td>
                      <td>{neighborhood}</td>
                      <td className={st.phone}>
                        <div>
                          {phone}
                          <a
                            href={`https://api.whatsapp.com/send?phone=55${whatsapp.replace(
                              /\D/g,
                              ''
                            )}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {' '}
                            <img
                              src={iconWhats}
                              alt="Icone WhatsApp"
                              width={18}
                              height={18}
                            />
                            <b>{whatsapp}</b>
                          </a>
                        </div>
                      </td>
                      <td className={st.email}>
                        <a
                          href={`mailto:${email}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src={iconEmail}
                            alt="Icone E-mail"
                            width={18}
                            height={18}
                          />
                          <b>{email}</b>
                        </a>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResultFindGuide
