import React, { useState, useEffect, ChangeEvent } from 'react'
import cn from 'classnames'
import * as st from '~/assets/styl/FilterForm.module.styl'
import { navigate } from 'gatsby'
import api from '~/api'
import searchIcon from '~/assets/img/search-icon.png'

interface FormProps {
  state: string
  setState: any
  setCity: any
  city: string
  setItems: any
  statesApi: {
    id: string
    name: string
  }[]
}

type CityProps = {
  id: string
  name: string
}

const FilterForm = ({
  state,
  setState,
  city,
  setCity,
  setItems,
  statesApi,
}: FormProps) => {
  const [error, setError] = useState('')
  const [cities, setCities] = useState<CityProps[]>([])
  const [formData, setFormData] = useState({
    estado: 'none',
    cidade: 'none',
  })
  const urlPage = '/encontre-seu-guia'

  useEffect(() => {
    navigate(`${urlPage}`)
  }, [])

  useEffect(() => {
    const updateForm = { ...formData }
    updateForm.cidade = city ? city : 'none'
    updateForm.estado = state ? state : 'none'

    if (state !== '') {
      handleCities(state, updateForm)
    } else {
      setCities([])
    }

    setFormData(updateForm)
  }, [state, city])

  const handleCities = async (state: string, updateForm: any) => {
    if (state !== '') {
      try {
        api('get', `shopping-guide/cities?state=${state}`)
          .then((data) => {
            const { cities } = data

            if (data && data.cities) {
              setCities(cities)

              if (updateForm) {
                if (city) {
                  updateForm.cidade = city
                }
                setFormData(updateForm)
              }
            }
          })
          .catch((err) => console.error(err))
      } catch (error) {
        console.log(error)
      }
    }
  }

  const handleChange = async (e: ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target
    const updateForm = { ...formData, [name]: value }

    setItems([])

    if (name === 'estado') {
      setState(value)
    } else if (name === 'cidade') {
      setCity(value)
    }

    setFormData(updateForm)
  }

  const submitSearch = (e: React.FormEvent) => {
    e.preventDefault()

    setItems([])

    if (state === '' || city === '') {
      setError('Escolha o estado e a cidade')
    } else {
      api('get', `shopping-guide?state=${state}&city=${city}`)
        .then((data) => {
          const { records } = data
          setItems(records)
        })
        .catch((err) => console.error(err))
    }

    if (state !== '' && city !== '') {
      const params = new URLSearchParams()

      params.set('estado', state)
      params.set('cidade', city)

      navigate(`${urlPage}?${params.toString()}`)

      setError('')
    }
  }

  return (
    <form className={st.form} id="form" onSubmit={(e) => submitSearch(e)}>
      <div className={st.formContainer}>
        <div className={st.wrapperForm}>
          <div className={st.formInput}>
            <label htmlFor="state">Estado</label>
            <select
              name="estado"
              id="state"
              required
              onChange={(e) => handleChange(e)}
              value={formData.estado}
            >
              <option value="none" disabled>
                Selecione o estado
              </option>
              {statesApi.map(({ id, name }) => (
                <option value={id} key={id}>
                  {name}
                </option>
              ))}
            </select>
          </div>
          <div className={cn(st.formInput, st.formSelect)}>
            <label htmlFor="city">Cidade</label>
            <select
              name="cidade"
              id="city"
              onChange={(e) => handleChange(e)}
              value={formData.cidade}
            >
              <option value="none" disabled>
                Selecione a cidade
              </option>
              {cities.map(({ id, name }) => (
                <option value={id} key={id}>
                  {name}
                </option>
              ))}
            </select>
          </div>
          <div className={st.formButton}>
            <button type="submit" className={st.formSearch}>
              <img
                src={searchIcon}
                alt="Icone de Busca"
                width={24}
                height={24}
              />
              Buscar
            </button>
          </div>
        </div>
        {error && <p className={st.error}>{error}</p>}
      </div>
    </form>
  )
}

export default FilterForm
