import React, { useEffect, useState } from 'react'
import Banner from '~/components/Banner'
import Breadcrumb from '~/components/Breadcrumb'
import { Head } from '~/components'
import FilterGuide from '~/components/FilterGuide'
import Footer from '~/components/Footer'
import bannerGuide from '~/assets/img/banner-guia.png'

const FindYourGuide = () => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.matchMedia('(max-width: 1080px)').matches
      setIsMobile(isMobile)
    }

    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <>
      <Head title={'Encontre o seu guia - ' + process.env.GATSBY_SITE_NAME} />
      <Banner
        bannerBackgroundColor="#BCBCBC"
        bannerTitle="Encontre o seu
        Guia de Compras"
        bannerImage={bannerGuide}
        bannerImageAlt="Foto de uma mulher e uma logo de um guia"
        imageBottomPosition={isMobile ? -2 : 22}
      />
      <Breadcrumb />
      <FilterGuide />
      <Footer />
    </>
  )
}

export default FindYourGuide
